<template>
  <div class="app-container admin-notify-list">
    <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
      <el-tab-pane
        :label="$t('notify.manager.info')"
        :name="listTab.tabName"
        :key="listTab.tabName"
      >
        <el-row class="filter-line">
          <el-col :span="4">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              @click="addNotify"
            >
              {{ $t("notify.manager.add") }}
            </el-button>
          </el-col>
          <el-col :span="20" :offset="8" align="right">
            <tenant-select
              style="width: 200px; margin-right: 10px"
              size="small"
              class="filter-item"
              v-model="notify.listQuery.tenantId"
              @change="handleNotifyFilter"
            ></tenant-select>
            <el-input
              :placeholder="$t('commons.searchPhrase')"
              maxlength="50"
              v-model="notify.listQuery.searchPhrase"
              clearable
              style="width: 200px; margin-right: 10px"
              class="filter-item"
              size="small"
              @keyup.enter.native="handleNotifyFilter"
            />
            <el-button
              :loading="notify.listLoading"
              class="filter-item"
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleNotifyFilter"
            >
              {{ $t("commons.search") }}
            </el-button>
          </el-col>
        </el-row>

        <el-table
          v-loading="notify.listLoading"
          :data="notify.list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          stripe
        >
          <el-table-column
            fixed
            type="index"
            align="center"
            :label="$t('commons.index')"
            width="95"
          >
          </el-table-column>
          <el-table-column prop="tenantName" :label="$t('tenant.tenantName')">
            <template slot-scope="scope">
              <span>{{ scope.row.tenantName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyTitle"
            :label="$t('notify.manager.title')"
            width="220"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.notifyTitle }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyType"
            :label="$t('notify.manager.objectType')"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.notifyType == 1">
                <span>{{ $t("notify.manager.platformPrivateLetters") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 2">
                <span>{{ $t("notify.manager.platformNotice") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 3">
                <span>{{ $t("notify.manager.sysPrivateLetters") }}</span>
              </template>
              <template v-if="scope.row.notifyType == 4">
                <span>{{ $t("notify.manager.sysNotice") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="notifyChannel"
            :label="$t('notify.manager.channel')"
          >
            <template slot-scope="scope">
              <template v-for="channel in scope.row.notifyChannel">
                <template v-if="channel == '1'"> Web&nbsp; </template>
                <template v-if="channel == '2'"> 短信&nbsp; </template>
                <template v-if="channel == '3'"> 邮件&nbsp; </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="isRecord"
            :label="$t('notify.manager.ifRecord')"
            width="100"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.isRecord == 'Y'">
                <span>{{ $t("commons.yes") }}</span>
              </template>
              <template v-if="scope.row.isRecord == 'N'">
                <span>{{ $t("commons.no") }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column :label="$t('commons.actions')">
            <template slot-scope="scope">
              <template v-if="scope.row.tenantId == -1">
                <el-tooltip
                  :content="$t('commons.edit')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    type="success"
                    circle
                    :disabled="scope.row.processing"
                    @click="updateNotify(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.delete')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    :loading="scope.row.processing"
                    @click="deleteNotify(scope.row.notifyId)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('notify.manager.send')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-message"
                    circle
                    :loading="scope.row.processing"
                    @click="sendNotify(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.copy')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-document-copy"
                    type="primary"
                    circle
                    @click="copyNotify(scope.row)"
                    :disabled="scope.row.processing"
                  >
                  </el-button>
                </el-tooltip>
              </template>
              <el-tooltip
                :content="$t('notify.manager.sendRecord')"
                placement="bottom"
                :open-delay="openDelay"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-tickets"
                  circle
                  @click="showNotifyTrace(scope.row)"
                >
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="notify.total > 0"
          :total="notify.total"
          :page.sync="notify.listQuery.current"
          :limit.sync="notify.listQuery.rowCount"
          @pagination="getNotifyPage"
        />
      </el-tab-pane>

      <!--新建或编辑通知-->
      <el-tab-pane
        v-if="editTab"
        :label="notify.title"
        :name="editTab.tabName"
        :key="editTab.tabName"
        closable
      >
        <el-form
          label-position="top"
          :model="notify.form"
          ref="notifyForm"
          :rules="notify.formRules"
          v-loading="editLoading"
        >
          <el-input
            type="hidden"
            class="hidden"
            v-model="notify.form.notifyId"
          ></el-input>
          <el-form-item :label="$t('notify.manager.title')" prop="notifyTitle">
            <el-input
              v-model="notify.form.notifyTitle"
              maxlength="80"
              :show-word-limit="true"
              autocomplete="off"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('notify.manager.content')"
            prop="notifyTemplateContent"
          >
            <tinymce-editor
              :id="editorId"
              ref="editor"
              v-model="notify.form.notifyTemplateContent"
              @handleImgUpload="imgUpload"
            />
          </el-form-item>
          <el-form-item
            :label="$t('notify.manager.objectType')"
            prop="notifyType"
          >
            <el-radio-group v-model="notify.form.notifyType">
              <el-radio :label="2">{{
                $t("notify.manager.platformNotice")
              }}</el-radio>
              <el-radio :label="1">{{
                $t("notify.manager.platformPrivateLetters")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            :label="$t('notify.manager.channel')"
            prop="notifyChannel"
          >
            <el-checkbox-group
              size="small"
              class="channelOptionsType"
              v-model="notify.form.notifyChannel"
            >
              <el-checkbox-button
                v-for="(item, key) in channelOptions"
                :key="key"
                :label="key"
                >{{ item }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="false"
            :label="$t('notify.manager.ifRecord')"
            prop="isRecord"
          >
            <el-radio-group v-model="notify.form.isRecord">
              <el-radio label="Y">{{ $t("commons.yes") }}</el-radio>
              <el-radio label="N">{{ $t("commons.no") }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          @click="handleNotifySave"
          v-loading.fullscreen.lock="notify.createLoading"
          >{{ $t("commons.save") }}
        </el-button>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加通知对话框 -->
    <el-dialog
      :visible.sync="notify.sendDialogVisible"
      :before-close="cancelNotifySend"
      width="30%"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <title-icon />{{ $t("notify.manager.send") }}
      </template>
      <el-form
        label-position="top"
        :model="notify.sendForm"
        ref="sendNotifyForm"
        :rules="notify.formRules"
      >
        <template v-if="this.notify.sendForm.notifyType == 1">
          <el-form-item
            :label="$t('notify.manager.receiver')"
            prop="userAccountList"
          >
            <account-select
              v-model="notify.sendForm.userAccountList"
              model="1"
              multiple
            ></account-select>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelNotifySend">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="handleNotifySend"
          v-loading.fullscreen.lock="notify.createLoading"
          >{{ $t("notify.manager.send") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 发送记录列表 -->
    <el-dialog
      :visible.sync="recordDialogVisible"
      :before-close="cancelSendRecord"
      width="50%"
    >
      <template slot="title"> <title-icon />{{ recordTitle }} </template>
      <el-table
        v-loading="recordListLoading"
        :data="recordList"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
        stripe
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-row>
                <el-col :span="12">
                  <el-form-item :label="$t('notify.manager.objectType')">
                    <template v-if="scope.row.notifyType == 1">
                      <span>{{
                        $t("notify.manager.platformPrivateLetters")
                      }}</span>
                    </template>
                    <template v-if="scope.row.notifyType == 2">
                      <span>{{ $t("notify.manager.platformNotice") }}</span>
                    </template>
                    <template v-if="scope.row.notifyType == 3">
                      <span>{{ $t("notify.manager.sysPrivateLetters") }}</span>
                    </template>
                    <template v-if="scope.row.notifyType == 4">
                      <span>{{ $t("notify.manager.sysNotice") }}</span>
                    </template>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('notify.manager.channel')">
                    <template
                      v-for="channel in scope.row.notifyChannel.split(',')"
                    >
                      <template v-if="channel == '1'"> Web&nbsp; </template>
                      <template v-if="channel == '2'"> 短信&nbsp; </template>
                      <template v-if="channel == '3'"> 邮件&nbsp; </template>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item :label="$t('notify.manager.notifyTime')">
                    <i class="el-icon-time" />
                    <span>{{
                      scope.row.notifyTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('notify.manager.readTime')">
                    <template v-if="scope.row.readTime != null">
                      <i class="el-icon-time" />
                    </template>
                    <span>{{
                      scope.row.readTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item :label="$t('notify.manager.title')">
                    <span>{{ scope.row.notifyTitle }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          prop="receiverName"
          :label="$t('notify.manager.receiver')"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.receiverName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="senderName"
          :label="$t('notify.manager.sender')"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.senderName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="readStatus"
          :label="$t('notify.manager.readStatus')"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.readStatus == 1">
              <span>{{ $t("notify.manager.unread") }}</span>
            </template>
            <template v-if="scope.row.readStatus == 2">
              <span>{{ $t("notify.manager.read") }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="sendTime"
          :label="$t('notify.manager.sendTime')"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('commons.actions')">
          <template slot-scope="scope">
            <el-tooltip
              :content="$t('notify.manager.findContent')"
              placement="bottom"
              :open-delay="openDelay"
            >
              <el-button
                size="mini"
                icon="el-icon-view"
                circle
                @click="findContent(scope.row)"
              >
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="recordTotal > 0"
        :total="recordTotal"
        :page.sync="recordListQuery.current"
        :limit.sync="recordListQuery.rowCount"
        @pagination="getNotifyDetailPage"
      />
    </el-dialog>

    <information-details
      :detailDrawer.sync="detailDrawer"
      :informationInfo="contentForm"
    />
  </div>
</template>

<script>
import {
  getNotifyPage,
  insertNotify,
  updateNotify,
  deleteNotify,
  pushNotify,
  getNotifyDetailPage,
  findNotifyById,
} from "@/api/ruge/notify/notify";
import Pagination from "@/components/Pagination";
import TenantSelect from "@/components/TenantSelect";
import AccountSelect from "@/components/AccountSelect";
import InformationDetails from "@/components/Information/components/informationDetails";

import TinymceEditor from "@/components/TinymceEditor";

import { envInfo } from "@/constants/envInfo";

const defineTab = {
  listTab: {
    tabName: "1",
  },
  editTab: {
    tabName: "2",
  },
};

export default {
  name: "Notify",
  components: {
    Pagination,
    TenantSelect,
    AccountSelect,
    InformationDetails,
    TinymceEditor,
  },
  data() {
    return {
      editorId: "editor-demo",

      editLoading: false,
      detailDrawer: false,
      contentForm: null,
      editTitle: null,
      activeTabName: defineTab.listTab.tabName,
      editTab: null,

      // Tooltip显示延迟时间(毫秒)
      openDelay: 1000,
      channelOptionsType: ["1", "2", "3"],
      channelOptions: { 1: "Web", 2: "短信", 3: "邮箱" },
      // 类别相关参数
      notify: {
        title: null,
        // 列表参数
        list: null,
        total: 0,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          searchPhrase: undefined,
          tenantId: -1,
        },
        // 新建对话框是否可见，默认不可见
        addDialogVisible: false,
        sendDialogVisible: false,
        // 添加通知加载状态
        createLoading: false,
        form: {
          notifyId: null,
          notifyTitle: null,
          notifyTemplateId: null,
          notifyTemplateContent: null,
          notifyType: 2,
          notifyChannel: ["1"],
          isRecord: "Y",
        },
        sendForm: {
          notifyId: null,
          notifyType: null,
          userAccountList: [],
        },
        // 表单校验规则
        formRules: {
          notifyTitle: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          notifyTemplateContent: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              validator: (rule, value, callback) => {
                //模版文本长度
                let strLen = value.length;
                //base64 字节长度算法
                let byteLength = strLen - (strLen / 8) * 2;
                let templateMaxSize = envInfo.templateMaxSize * 1024 * 1024;
                //模版过大校验
                if (byteLength > templateMaxSize) {
                  callback(new Error(this.$t("notify.manager.templateMax")));
                }
                callback();
              },
              trigger: ["blur", "change"],
            },
          ],
          notifyType: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          notifyChannel: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          userAccountList: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
      recordDialogVisible: false,
      recordForm: {
        notifyTitle: null,
        notifyTime: null,
        readStatus: null,
        readTime: null,
        createdBy: null,
        userAccount: null,
        creationDate: null,
        senderName: null,
        receiverName: null,
      },
      recordListQuery: {
        current: 1,
        rowCount: 10,
        notifyId: null,
      },
      recordListLoading: false,
      recordList: null,
      recordTotal: 0,
      recordTitle: null,
    };
  },
  watch: {
    "notify.form.notifyChannel": {
      handler(value) {
        // 不能取消Web选项
        if (!value.includes("1")) {
          value.push("1");
        }
      },
    },
  },
  created() {
    this.getNotifyPage();
  },
  computed: {
    listTab() {
      return defineTab.listTab;
    },
  },
  methods: {
    // 富文本上传图片
    imgUpload(blobInfo, success) {
      const imgBase64 = `data:${
        blobInfo.blob().type
      };base64,${blobInfo.base64()}`;
      success(imgBase64);
    },

    // 关闭项Tab
    removeTab(tabName) {
      this.activeTabName = defineTab.listTab.tabName;
      if (tabName == "2") {
        this.editTab = null;
      }
    },
    //查询通知
    getNotifyPage() {
      this.notify.listLoading = true;
      getNotifyPage(this.notify.listQuery)
        .then((response) => {
          this.notify.list = response.rows.map((v) => {
            v.notifyChannel = v.notifyChannel.toString().split(",");
            return v;
          });
          this.notify.total = response.total;
          this.notify.listLoading = false;
        })
        .catch((error) => {
          this.notify.listLoading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    // 取消发送
    cancelNotifySend() {
      this.$refs.sendNotifyForm.resetFields();
      this.notify.sendDialogVisible = false;
      this.notify.sendForm.userAccountList = [];
    },
    //保存通知
    handleNotifySave() {
      this.$refs.notifyForm.validate((valid) => {
        if (valid) {
          this.notify.createLoading = true;
          this.notify.form.notifyChannel =
            this.notify.form.notifyChannel.toString();
          if (this.notify.form.notifyId) {
            // 调用服务
            updateNotify(this.notify.form)
              .then(() => {
                // 成功后重置form,并关闭弹框和遮罩层
                this.notify.addDialogVisible = false;
                this.notify.createLoading = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                // 刷新列表
                this.getNotifyPage();
              })
              .catch((error) => {
                this.notify.createLoading = false;
                console.log(`修改失败，原因 => ${error}`);
              });
          } else {
            // 调用服务
            insertNotify(this.notify.form)
              .then(() => {
                // 成功后重置form,并关闭弹框和遮罩层
                this.notify.addDialogVisible = false;
                this.notify.createLoading = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                // 刷新列表
                this.getNotifyPage();
              })
              .catch((error) => {
                this.notify.createLoading = false;
                console.log(`添加失败，原因 => ${error}`);
              });
          }
        } else {
          console.log("error handleNotifySave!!");
          return false;
        }
      });
    },
    //新增通知
    addNotify() {
      this.clearForm();
      this.notify.title = this.$t("notify.manager.add");
      this.editTab = defineTab.editTab;
      this.activeTabName = defineTab.editTab.tabName;
    },
    // 查询通知
    handleNotifyFilter() {
      this.notify.listQuery.current = 1;
      this.getNotifyPage();
    },
    //修改通知
    updateNotify(row) {
      this.notify.title = this.$t("notify.manager.edit");
      this.editTab = defineTab.editTab;
      this.activeTabName = defineTab.editTab.tabName;
      this.editLoading = true;
      findNotifyById({ notifyId: row.notifyId })
        .then((res) => {
          this.notify.form = res;
          this.notify.form.notifyChannel = res.notifyChannel.split(",");
          //加载富文本
          window.tinymce
            .get(this.editorId)
            .setContent(res.notifyTemplateContent);
        })
        .finally(() => {
          this.editLoading = false;
        });
    },
    //删除通知
    deleteNotify(notifyId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteNotify({ notifyId: notifyId })
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              // 刷新列表
              this.getNotifyPage();
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    //复制通知
    copyNotify(row) {
      this.notify.title = this.$t("notify.manager.copy");
      this.editTab = defineTab.editTab;
      this.activeTabName = defineTab.editTab.tabName;
      this.editLoading = true;
      findNotifyById({ notifyId: row.notifyId })
        .then((res) => {
          this.notify.form = res;
          this.notify.form.notifyChannel = res.notifyChannel.split(",");
          //加载富文本
          window.tinymce
            .get(this.editorId)
            .setContent(res.notifyTemplateContent);
          this.notify.form.notifyId = null;
        })
        .finally(() => {
          this.editLoading = false;
        });
    },
    //清空表单
    clearForm() {
      this.notify.form.notifyId = null;
      this.notify.form.notifyTitle = null;
      this.notify.form.notifyTemplateId = null;
      this.notify.form.notifyTemplateContent = null;
      this.notify.form.notifyType = 2;
      this.notify.form.notifyChannel = ["1"];
      this.notify.form.isRecord = "Y";
      if (this.$refs.notifyForm) {
        this.$refs.notifyForm.resetFields();
      }
    },
    sendNotify(row) {
      if (row.notifyType == 2) {
        this.$confirm(
          this.$t("notify.manager.sendConfirmPrompt"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            //发送通知服务
            pushNotify({ notifyId: row.notifyId })
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.operationSuccess"),
                  type: "success",
                });
                this.notify.sendDialogVisible = false;
                this.notify.sendForm.userAccountList = [];
              })
              .catch((error) => {
                console.log(`发送失败，原因 => ${error}`);
              });
          })
          .catch((error) => {
            console.log(`未发送，原因 => ${error}`);
          });
      } else {
        this.notify.sendForm.notifyId = row.notifyId;
        this.notify.sendForm.userAccountList = [];
        this.notify.sendForm.notifyType = row.notifyType;
        this.notify.sendDialogVisible = true;
      }
    },
    //发送通知
    handleNotifySend() {
      this.$refs.sendNotifyForm.validate((valid) => {
        if (valid) {
          this.$confirm(
            this.$t("notify.manager.sendConfirmPrompt"),
            this.$t("commons.warning"),
            {
              confirmButtonText: this.$t("commons.confirm"),
              cancelButtonText: this.$t("commons.cancel"),
              type: "warning",
            }
          )
            .then(() => {
              let notifyId = this.notify.sendForm.notifyId;
              let userAccountList =
                this.notify.sendForm.userAccountList.toString();
              //发送通知服务
              pushNotify({
                notifyId: notifyId,
                userAccountList: userAccountList,
              })
                .then(() => {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.operationSuccess"),
                    type: "success",
                  });
                  this.notify.sendDialogVisible = false;
                  this.notify.sendForm.userAccountList = [];
                })
                .catch((error) => {
                  console.log(`发送失败，原因 => ${error}`);
                });
            })
            .catch((error) => {
              console.log(`未发送，原因 => ${error}`);
            });
        } else {
          console.log("error handleNotifySend!!");
          return false;
        }
      });
    },
    showNotifyTrace(row) {
      this.recordTitle = this.$t("notify.manager.sendRecord");
      this.recordDialogVisible = true;
      this.recordListQuery.notifyId = row.notifyId;
      this.getNotifyDetailPage();
    },
    getNotifyDetailPage() {
      this.recordListLoading = true;
      getNotifyDetailPage(this.recordListQuery)
        .then((response) => {
          this.recordList = response.rows;
          this.recordTotal = response.total;
          this.recordListLoading = false;
        })
        .catch((error) => {
          this.recordListLoading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    cancelSendRecord() {
      this.recordDialogVisible = false;
    },
    findContent(row) {
      this.contentForm = row;
      this.detailDrawer = true;
    },
  },
};
</script>

<style lang="less" scoped>
.admin-notify-list {
  .filter-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
</style>